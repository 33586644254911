<script setup lang="ts">
import { Corners, AnyBorder } from './ClipContainer.vue';

export interface ButtonProps {
    isEnabled?: boolean;
    corners?: Corners;
    border?: AnyBorder;
    backgroundProgress?: number;
}

withDefaults(
    defineProps<ButtonProps>(),
    {
        isEnabled: true,
        corners: undefined,
        border: undefined,
        backgroundProgress: undefined,
    },
);

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonClipContainer
        class="common-button"
        :class="{ 'common-button--disabled': !isEnabled }"
        role="button"
        :corners="corners"
        :border="border"
        clip="content"
        :background-path-style="{ transition: 'fill 0.2s ease-out' }"
        :border-path-style="{ transition: 'stroke 0.2s ease-out' }"
        :triangle-path-style="{ transition: 'fill 0.2s ease-out' }"
        @click="isEnabled && emit('click', $event)"
    >
        <div class="common-button__content">
            <slot />
        </div>
        <div
            class="common-button__progress"
            :style="{
                '--progress': backgroundProgress,
            }"
        />
    </CommonClipContainer>
</template>

<style lang="scss" scoped>
.common-button {
    cursor: pointer;

    &__progress {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;

        background: var(--progress-background-color);

        clip-path: inset(0 calc((1 - var(--progress)) * 100%) 0 0);
    }

    &__content {
        position: relative;
        z-index: 1;

        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    --background-color: var(--enabled-background-color);
    --border-color: var(--enabled-border-color);

    &:hover {
        --background-color: var(--hover-background-color);
        --border-color: var(--hover-border-color);
    }

    &:active {
        --background-color: var(--active-background-color);
        --border-color: var(--active-border-color);
    }

    &--disabled {
        cursor: not-allowed;

        --background-color: var(--disabled-background-color);
        --border-color: var(--disabled-border-color);

        &:hover {
            --background-color: var(--disabled-hover-background-color);
            --border-color: var(--disabled-hover-border-color);
        }

        &:active {
            --background-color: var(--disabled-active-background-color);
            --border-color: var(--disabled-active-border-color);
        }
    }
}
</style>
